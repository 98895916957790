module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!imgPath) var imgPath='';
if (!videoPath) var videoPath='';
if (!title) var title = '';
;
__p += '\r\n<section class="common-video-swiper">\r\n  <div class="swiper mySwiper">\r\n    <div class="swiper-wrapper">\r\n      <div class="swiper-slide" data-fancybox href="' +
((__t = ( videoPath )) == null ? '' : __t) +
'">\r\n        <div class="img" data-scroll data-scroll-speed="-.1" style="background-image: url(' +
((__t = ( imgPath )) == null ? '' : __t) +
');"></div>\r\n        <div class="icon"><i class="iconfont icon-bofang"></i></div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class="swiper-contral">\r\n    <div class="common-tag">\r\n      <div class="tag">\r\n        <p>' +
((__t = ( title )) == null ? '' : __t) +
'</p>\r\n        <div class="jiao"><b></b><b></b><b></b><b></b></div>\r\n      </div>\r\n    </div>\r\n    <div class="swiper-button-next"><img src="' +
((__t = (require('../../assets/images/btn_right.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n    <div class="swiper-button-prev"><img src="' +
((__t = (require('../../assets/images/btn_left.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n  </div>\r\n</section>';

}
return __p
}