import './index.scss'
import Swiper from 'swiper/dist/js/swiper.min.js'

if($('.common-btm-link').length > 0){
  var commonBtmLinkSwiper = new Swiper(".common-btm-link .mySwiper", {
    speed: 1500,
    watchOverflow: true,
    slidesPerView : 2,
    slidesPerGroup : 2,
    spaceBetween : 115,
    navigation: {
      nextEl: ".common-btm-link .swiper-button-next",
      prevEl: ".common-btm-link .swiper-button-prev",
    },
    breakpoints: {
      1600: {
        slidesPerView : 2,
        slidesPerGroup : 2,
        spaceBetween : 85,
      },
      1440: {
        slidesPerView : 2,
        slidesPerGroup : 2,
        spaceBetween : 65,
      },
      1280: {
        slidesPerView : 2,
        slidesPerGroup : 2,
        spaceBetween : 45,
      },
      767:{
        slidesPerView : 1,
        slidesPerGroup : 1,
        spaceBetween : 30,
      }
    }
  });
}