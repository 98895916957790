module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="page-header">\r\n  <div class="logo"><a href="index.html"><img src="' +
((__t = (require('../../assets/images/logo1.png'))) == null ? '' : __t) +
'" alt=""></a></div>\r\n</section>';

}
return __p
}