module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n  <div class="header">\r\n    <!-- <div class="logo"><a href="index.html"><img src="' +
((__t = (require('../../assets/images/logo1.png'))) == null ? '' : __t) +
'" alt=""></a></div> -->\r\n    <div class="menu-icon"><div><span></span></div></div>\r\n    <div class="yuding-btn">\r\n      <div>立即预订</div>\r\n      <div class="img"><img src="' +
((__t = (require('../../assets/images/ewm_xcx.jpg'))) == null ? '' : __t) +
'" alt=""></div>\r\n    </div>\r\n  </div>\r\n  <div class="hidden-header">\r\n    <div class="bg-img tran_2000"><b style="background-image: url(' +
((__t = (require('./images/header_bg.jpg'))) == null ? '' : __t) +
');"></b></div>\r\n    <div class="cont">\r\n      <div class="close-btn"><i class="iconfont icon-guanbi"></i></div>\r\n      <div class="yuding-btn">\r\n        <div class="t"><span>立即预订</span></div>\r\n        <div class="img"><img src="' +
((__t = (require('../../assets/images/ewm_xcx.jpg'))) == null ? '' : __t) +
'" alt=""></div>\r\n      </div>\r\n      <div class="flex">\r\n        <div class="nav">\r\n          <ul>\r\n            <li><a href="index.html">\r\n              <div class="nav-title"><span>首页</span></div>\r\n              <div class="btm-line"><b></b></div>\r\n              <div class="nav-img"><div><b style="background-image: url(' +
((__t = (require('./images/header_img1.jpg'))) == null ? '' : __t) +
');"></b></div></div>\r\n            </a></li>\r\n            <li><a href="hot_spring.html">\r\n              <div class="nav-title"><span>特色温泉</span></div>\r\n              <div class="btm-line"><b></b></div>\r\n              <div class="nav-img"><div><b style="background-image: url(' +
((__t = (require('./images/header_img1.jpg'))) == null ? '' : __t) +
');"></b></div></div>\r\n            </a></li>\r\n            <li><a href="apartment.html">\r\n              <div class="nav-title"><span>房型介绍</span></div>\r\n              <div class="btm-line"><b></b></div>\r\n              <div class="nav-img"><div><b style="background-image: url(' +
((__t = (require('./images/header_img1.jpg'))) == null ? '' : __t) +
');"></b></div></div>\r\n            </a></li>\r\n            <li><a href="entertainment.html">\r\n              <div class="nav-title"><span>娱乐休闲</span></div>\r\n              <div class="btm-line"><b></b></div>\r\n              <div class="nav-img"><div><b style="background-image: url(' +
((__t = (require('./images/header_img1.jpg'))) == null ? '' : __t) +
');"></b></div></div>\r\n            </a></li>\r\n            <li><a href="food.html">\r\n              <div class="nav-title"><span>餐饮美食</span></div>\r\n              <div class="btm-line"><b></b></div>\r\n              <div class="nav-img"><div><b style="background-image: url(' +
((__t = (require('./images/header_img1.jpg'))) == null ? '' : __t) +
');"></b></div></div>\r\n            </a></li>\r\n            <li><a href="contact.html">\r\n              <div class="nav-title"><span>联系我们</span></div>\r\n              <div class="btm-line"><b></b></div>\r\n              <div class="nav-img"><div><b style="background-image: url(' +
((__t = (require('./images/header_img1.jpg'))) == null ? '' : __t) +
');"></b></div></div>\r\n            </a></li>\r\n          </ul>\r\n          <div class="right-link">\r\n            <a href="#"><span>酒店须知</span></a><a href="#"><span>隐私政策</span></a>\r\n          </div>\r\n        </div>\r\n        <div class="left">\r\n          <div class="logo"><a href="#"><img src="' +
((__t = (require('../../assets/images/logo2.png'))) == null ? '' : __t) +
'" alt=""></a></div>\r\n          <div class="info">\r\n            <div class="item">\r\n              <h3>预订电话</h3>\r\n              <p class="tel"><a href="tel:+0875-5150333">0875-5150333</a></p>\r\n            </div>\r\n            <div class="item">\r\n              <h3>详细地址</h3>\r\n              <p><a href="https://www.amap.com/place/B0I09ZLWL0" target="_blank">云南腾冲北海乡玛御谷<br>大端温泉度假酒店</a></p>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</header>';

}
return __p
}