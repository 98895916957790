module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="common-btm-link">\r\n  <div class="swiper mySwiper">\r\n    <div class="swiper-wrapper">\r\n      <div class="swiper-slide"><a href="#">\r\n        <div class="img tran_3000" style="background-image: url(' +
((__t = (require('../../assets/images/btm_link_img1.jpg'))) == null ? '' : __t) +
');" data-scroll data-scroll-speed=".1"></div>\r\n        <div class="text">\r\n          <h2 class="flourish white no_overflow" data-scroll data-scroll-repeat>  \r\n            <span class="inline_block tran_3000 top_hidden d200">Food</span>\r\n            <span class="inline_block tran_3000 top_hidden d300">And</span>\r\n            <span class="inline_block tran_3000 top_hidden d400">Beverage</span>\r\n          </h2>\r\n          <div class="heng-line white tran_3000" data-scroll data-scroll-repeat></div>\r\n          <div class="desc tran_3000 top_low" data-scroll data-scroll-repeat>[ 餐饮美食 ]</div>\r\n        </div>\r\n        <div class="btn"><span>发现</span></div>\r\n      </a></div>\r\n      <div class="swiper-slide"><a href="#">\r\n        <div class="img tran_3000" style="background-image: url(' +
((__t = (require('../../assets/images/btm_link_img2.jpg'))) == null ? '' : __t) +
');" data-scroll data-scroll-speed=".1"></div>\r\n        <div class="text">\r\n          <h2 class="flourish white no_overflow" data-scroll data-scroll-repeat> \r\n            <span class="inline_block tran_3000 top_hidden d200">Leisure</span>\r\n            <span class="inline_block tran_3000 top_hidden d300">Entertainment</span>\r\n          </h2>\r\n          <div class="heng-line white tran_3000" data-scroll data-scroll-repeat></div>\r\n          <div class="desc tran_3000 top_low" data-scroll data-scroll-repeat>[ 休闲娱乐 ]</div>\r\n        </div>\r\n        <div class="btn"><span>发现</span></div>\r\n      </a></div>\r\n      <div class="swiper-slide"><a href="#">\r\n        <div class="img tran_3000" style="background-image: url(' +
((__t = (require('../../assets/images/btm_link_img1.jpg'))) == null ? '' : __t) +
');" data-scroll data-scroll-speed=".1"></div>\r\n        <div class="text">\r\n          <h2 class="flourish white no_overflow" data-scroll data-scroll-repeat>  \r\n            <span class="inline_block tran_3000 top_hidden d200">Food</span>\r\n            <span class="inline_block tran_3000 top_hidden d300">And</span>\r\n            <span class="inline_block tran_3000 top_hidden d400">Beverage</span>\r\n          </h2>\r\n          <div class="heng-line white tran_3000" data-scroll data-scroll-repeat></div>\r\n          <div class="desc tran_3000 top_low" data-scroll data-scroll-repeat>[ 餐饮美食 ]</div>\r\n        </div>\r\n        <div class="btn"><span>发现</span></div>\r\n      </a></div>\r\n    </div>\r\n    <div class="swiper-button-next"></div>\r\n    <div class="swiper-button-prev"></div>\r\n    <div class="center">\r\n      <div class="logo"><img src="' +
((__t = (require('../../assets/images/logo2_white.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n      <div class="text">为宾客打造24小时舒服自在的入住体验</div>\r\n      <div class="icon"><img src="' +
((__t = (require('../../assets/images/icon_img3.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}