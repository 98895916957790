module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="ny-banner">\r\n  <div class="bg-img" data-scroll data-scroll-speed="-.2" data-scroll-event-progress="nybanner">\r\n    <b class="tran_3000" style="background-image: url(' +
((__t = (require('../../assets/images/banner_spring.jpg'))) == null ? '' : __t) +
');"></b>\r\n  </div>\r\n  <div class="logo"><a href="index.html"><img src="' +
((__t = (require('../../assets/images/logo1_white.png'))) == null ? '' : __t) +
'" alt=""></a></div>\r\n  <div class="btm-text" data-scroll>\r\n    <h2 class="flourish no_overflow">\r\n      <span class="inline_block top_hidden tran_3000 d200">Thermal</span>&nbsp;\r\n      <span class="inline_block top_hidden tran_3000 d300">Spring</span>\r\n    </h2>\r\n    <div class="t1"><span class="top_low inline_block tran_3000 d400">[ 舒爽温泉  悠然享受 ]</span></div>\r\n    <div class="heng-line tran_3000"></div>\r\n    <div class="icon"><img src="' +
((__t = (require('../../assets/images/icon_img3.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n  </div>\r\n</section>';

}
return __p
}