import './index.scss'
import Swiper from 'swiper/dist/js/swiper.min.js'
if($('.common-video-swiper').length > 0){
  var videoSwiper = new Swiper(".common-video-swiper .mySwiper", {
    speed: 1500,
    watchOverflow: true,
    navigation: {
      nextEl: ".common-video-swiper .swiper-button-next",
      prevEl: ".common-video-swiper .swiper-button-prev",
    },
  });
}