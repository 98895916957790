module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="btm-section no_overflow">\r\n  <div class="bg-img" data-scroll data-scroll-speed=".1" style="background-image: url(' +
((__t = (require('./images/btm_section_img1.jpg'))) == null ? '' : __t) +
');"></div>\r\n  <div class="text">\r\n    <h2 class="no_overflow flourish" data-scroll data-scroll-repeat>\r\n      <span class="inline_block tran_3000 top_hidden d100">Theme</span>&nbsp;\r\n      <span class="inline_block tran_3000 top_hidden d200">Room</span>&nbsp;\r\n      <span class="inline_block tran_3000 top_hidden d300">Type</span>&nbsp;\r\n    </h2>\r\n    <div class="heng-line tran_3000" data-scroll data-scroll-repeat></div>\r\n    <div class="btn tran_3000 top_low d400" data-scroll data-scroll-repeat><a href="javascript:void(0)">发现</a></div>\r\n    <div class="icon tran_3000 top_low d600" data-scroll data-scroll-repeat><img src="' +
((__t = (require('../../assets/images/icon_img3.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n  </div>\r\n  <div class="text2">\r\n    <div class="logo"><img src="' +
((__t = (require('../../assets/images/logo2_white.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n    <p>为客人打造24小时全域自 在、健康、舒适的体验</p>\r\n  </div>\r\n</section>';

}
return __p
}