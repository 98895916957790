module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer class="container">\r\n  <div class="footer">\r\n    <div class="left">\r\n      <div class="logo"><a href="index.html"><img src="' +
((__t = (require('../../assets/images/logo1.png'))) == null ? '' : __t) +
'" alt=""></a></div>\r\n      <div class="info">\r\n        <div class="item">\r\n          <h5>预订电话</h5>\r\n          <p><a href="tel:+0875-5150333">0875-5150333</a></p>\r\n        </div>\r\n        <div class="item">\r\n          <h5>地址</h5>\r\n          <p><a href="https://www.amap.com/place/B0I09ZLWL0" target="_blank">云南省腾冲市北海乡玛御谷温泉小镇清润园S1-S2</a></p>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class="nav">\r\n      <h5>目录</h5>\r\n      <ul>\r\n        <li><a href="index.html">首页</a></li>\r\n        <li><a href="hot_spring.html">特色温泉</a></li>\r\n        <li><a href="apartment.html">房型介绍</a></li>\r\n        <li><a href="entertainment.html">娱乐休闲</a></li>\r\n        <li><a href="food.html">餐饮美食</a></li>\r\n        <li><a href="contact.html">联系我们</a></li>\r\n      </ul>\r\n    </div>\r\n    <div class="right">\r\n      <div class="item ewm-cont">\r\n        <h5>预定小程序</h5>\r\n        <div class="ewm">\r\n          <div class="icon"><i class="iconfont icon-ico"></i></div>\r\n          <div class="img"><img src="' +
((__t = (require('../../assets/images/ewm_xcx.jpg'))) == null ? '' : __t) +
'" alt=""></div>\r\n        </div>\r\n      </div>\r\n      <div>\r\n        <div class="item"><h5><a href="#">酒店须知</a></h5></div>\r\n        <div class="item"><h5><a href="#">隐私政策</a></h5></div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class="copyright">Copyright <script language="JavaScript">var today = new Date();document.write(today.getFullYear())</script> <a href="/">腾冲大端温泉度假酒店有限公司</a>版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">滇ICP备2023002649号-1</a></div>\r\n</footer>';

}
return __p
}